<template>
  <el-popover placement="bottom" width="350" trigger="click" @show="showList" @hide="hide">
    <el-row class="pad-10" v-if="!isDetail">
      <el-col :span="12" class="ftz-16 pad-l-0"> 消息通知 </el-col>
      <el-col :span="12" v-if="noticeList.length > 0">
        <el-link type="primary" class="pull-right ftz-12" @click="setAllRead">全部已读</el-link></el-col
      >
    </el-row>
    <template v-if="isDetail">
      <el-page-header @back="goBack" :content="currentNotice.title"> </el-page-header>
      <el-divider></el-divider>
      <el-descriptions title="" :column="1" :colon="false">
        <el-descriptions-item label="发送人">
          <el-tag size="small">{{ currentNotice.sender }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="发送时间">{{ currentNotice.createTime }}</el-descriptions-item>
        <el-descriptions-item label="内容">{{ currentNotice.content }}</el-descriptions-item>
      </el-descriptions>
    </template>
    <el-collapse v-if="!isDetail">
      <template v-if="noticeList.length == 0">
        <el-empty description="暂无未读消息" :image-size="30"></el-empty>
      </template>
      <template v-else>
        <el-collapse-item v-for="item of noticeList" :name="item" @click.native="handleChange(item)">
          <template slot="title">
            <span class="notice-icon"></span>
            <span class="notice-title">{{ item.title }}</span>
          </template>
          <div class="pad-t-10">{{ item.content }}</div>
        </el-collapse-item>
      </template>
    </el-collapse>
    <el-link type="primary" @click="allNotice()" class="pull-right ftz-12 mar-t-10">查看全部>></el-link>
    <div slot="reference">
      <el-badge :hidden="countRedPoint == 0" :value="countRedPoint" :max="99">
        <i class="el-icon-bell"></i>
      </el-badge>
    </div>
  </el-popover>
</template>

<script>
import { mapGetters } from "vuex"

import { getCountRedPoint, getUserMessageList, readMessage, setAllRead } from "@/api/desk/notice"
export default {
  name: "top-notice",
  data() {
    return {
      activeName: "third",
      noticeList: [],
      countRedPoint: 0,
      currentNotice: {},
      isDetail: false,
    }
  },
  created() {
    this.getCountRedPoint()
    this.getCount()
    this.getInstantUserMessageList()
  },
  watch: {
    "$store.state.user.noticeRefresh": function (val) {
      this.getCount()
    },
  },
  methods: {
    handleChange(val) {
      if (val.id) {
        this.isDetail = !this.isDetail
        this.currentNotice = val
        readMessage(val.id).then((res) => {
          this.getCount()
          this.getInstantUserMessageList()
        })
      }
    },
    allNotice() {
      this.$router.push({
        path: "/notice/noticeByUser",
      })
    },
    goBack() {
      this.isDetail = !this.isDetail
    },
    showList() {
      this.getCount()
      this.getInstantUserMessageList()
    },
    hide() {
      this.isDetail = false
    },
    getInstantUserMessageList() {
      let params = {
        current: 1,
        size: 5,
        status: 1,
      }
      getUserMessageList(params).then((res) => {
        this.noticeList = res.data.data.records
      })
    },
    getCountRedPoint() {
      setInterval(() => {
        this.getCount()
      }, 300000)
    },
    getCount() {
      getCountRedPoint().then((res) => {
        this.countRedPoint = res.data.data
        this.$forceUpdate()
      })
    },
    setAllRead() {
      setAllRead().then((res) => {
        this.getCount()
        this.getInstantUserMessageList()
      })
    },
    pageChange(page, done) {
      setTimeout(() => {
        this.$message.success("页码" + page)
        this.data = this.data.concat(list)
        done()
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-badge__content {
  border: 1px solid transparent !important;
}
.notice-icon {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #ffffff;
  display: inline-block;
  /* font-size: 12px; */
  height: 5px;
  line-height: 5px;
  /* padding: 0 3px; */
  text-align: center;
  white-space: nowrap;
  width: 5px;
  margin-right: 5px;
}
.ftz-16 {
  font-size: 16px;
}
.ftz-12 {
  font-size: 12px;
}
.notice-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 250px;
}
</style>
